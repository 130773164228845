/**
 * Compares two arrays or a part inside the two arrays.
 *
 * @param array1  The first array to compare.
 * @param array2  The second array to compare.
 * @param start   Optional start index of the comparison. When not specified then 0 is used.
 * @param length  Optional length of the block to compare. When not specified then the length of the larger array is
 *                used minus the starting index.
 * @return True when array contents (or the specified part of it) are equal, false when not.
 */
export function equals<T>(array1: ArrayLike<T>, array2: ArrayLike<T>, start: number = 0, length?: number): boolean {
    // Apply default length when necessary
    if (length == null) {
        length = Math.max(array1.length, array2.length) - start;
    }

    // Compare the arrays
    for (let i = start, max = start + length; i !== max; ++i) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
}


export class Validation {
    // relevant regex padderns
    static second_sentence = /.[.?!]\s[A-Z]/;
    static space_after_punctuation = /(.[.?!]\s\S?\s?)/;
    static double_space = /[a-z]?\s\s./;
    static spaceAfterWord = /\w\s\w?\s?/;
    static newWord = /\w\s\w/;


    public checkRegex(stringValue: string, regexExpression: any): boolean {
        if(stringValue.match(regexExpression))
        {
            return true;
        }
        return false;
    }
    
    public validateUpToTwoSentences(stringToCheck: string): string | null {
        // This delays the switch statement for 2 seconds.
        setTimeout(() =>{
            switch(true)
            {   
                // Check for double space and for a second sentence
                case this.checkRegex(stringToCheck, Validation.double_space) && this.checkRegex(stringToCheck, Validation.second_sentence):
                    const double_space_index_2: number = stringToCheck.indexOf("  ");
                    stringToCheck = stringToCheck.slice(0, double_space_index_2) + stringToCheck.slice(double_space_index_2 + 1);
                    let firstPunctuation1:number = stringToCheck.search(/[.?!]/);
                    let newString1 = stringToCheck.slice(firstPunctuation1+1);
                    if (this.checkRegex(newString1, Validation.space_after_punctuation))
                    {
                        const dot_index: number = newString1.search(/[.?!]/);
                        newString1 = newString1.slice(0, dot_index + 1);
                        stringToCheck = stringToCheck.slice(0,firstPunctuation1 + 1) + newString1;
                        return stringToCheck;
        
                    }
                    return stringToCheck;
        
                // Check for double space and for space after punctuation    
                case this.checkRegex(stringToCheck, Validation.double_space) && this.checkRegex(stringToCheck, Validation.space_after_punctuation):
                    const double_space_index_3: number = stringToCheck.indexOf("  ");
                    stringToCheck = stringToCheck.slice(0, double_space_index_3) + stringToCheck.slice(double_space_index_3 + 1);
                    const dot_index_2: number = stringToCheck.search(/[.?!]/); 
                    stringToCheck = stringToCheck.slice(0, dot_index_2 + 1);
                    return stringToCheck;
        
                // Check for a second sentence    
                case this.checkRegex(stringToCheck, Validation.second_sentence):
                    let firstPunctuation:number = stringToCheck.search(/[.?!]/);
                    let newString = stringToCheck.slice(firstPunctuation+1);
                    if (this.checkRegex(newString, Validation.space_after_punctuation))
                    {
                        const dot_index: number = newString.search(/[.?!]/); 
                        newString = newString.slice(0, dot_index + 1);
                        stringToCheck = stringToCheck.slice(0, firstPunctuation + 1) + newString;
                        return stringToCheck;
                    }
                    return stringToCheck;
        
                // Check for double space  
                case this.checkRegex(stringToCheck, Validation.double_space):
                    const double_space_index: number = stringToCheck.indexOf("  ");
                    stringToCheck = stringToCheck.slice(0, double_space_index) + stringToCheck.slice(double_space_index + 1);
                    return stringToCheck;
        
                // Check for space after punctuation   
                case this.checkRegex(stringToCheck, Validation.space_after_punctuation):
                    const dot_index: number = stringToCheck.search(/[.?!]/); 
                    stringToCheck = stringToCheck.slice(0, dot_index + 1);
                    return stringToCheck;
                default:
                    return stringToCheck;
            }},2000)
            return stringToCheck;
        
    
    }
    
    public validateUpToTwoWords(stringToCheck: string): string | null {
        // This delays the switch statement for 2 seconds.
    
        switch(true)
        {
            // Check for a new word and for space after the second word
            case this.checkRegex(stringToCheck, Validation.newWord):
                let firstEnd:number = stringToCheck.search(/\w\s/);
                let newString = stringToCheck.slice(firstEnd + 1);
                if (this.checkRegex(newString, Validation.spaceAfterWord))
                {
                    const index: number = newString.search(/\w\s/);
                    newString = newString.slice(0, index + 1);
                    stringToCheck = stringToCheck.slice(0,firstEnd + 1) + newString;
                    return stringToCheck;
                }
                return stringToCheck;

            // Check for space after the first word    
            case this.checkRegex(stringToCheck, Validation.spaceAfterWord):
                const index: number = stringToCheck.search(Validation.spaceAfterWord); 
                stringToCheck = stringToCheck.slice(0, index + 1);
                return stringToCheck;
            default:
                return stringToCheck;
    
        }
    
    }
    
}
