import { Injectable } from '@angular/core';

import { UserService } from './user.service';
import { Storage } from '@ionic/storage-angular';
import { User } from './user';
import { HttpClient } from '@angular/common/http';
import { PurchaseService } from './purchase.service';
import { AnalyticsService } from './analytics.service';

@Injectable()
export class DailyStreakService {
  private _storage: Storage | null = null;
  public startVal = 5;
  public defaultVal = 1;
  private user?: User;

  constructor(
    private storage: Storage,
    public userService: UserService,
    private httpClient: HttpClient,
    public purchaseService: PurchaseService,
    public analyticsService: AnalyticsService
    ) {
      this.init();
    }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public setOriginTime()
  {
    let currentDate = new Date();
    this._storage?.set("originTime", currentDate.toISOString());
  }

  public async checkTime(): Promise<boolean>
  {
    let hours = 0;
    await this._storage?.get("originTime").then((time: number) =>
    {
      if(time)
      {
        let prevDate = new Date(time);
        let currentDate = new Date();
        
        let diff = currentDate.getTime() - prevDate.getTime();
        hours = diff / (1000 * 60 * 60);
      }
      else {
        this.setOriginTime();
      }
    });
    return (hours >= 24);
  }

  public async checkReward(user: User): Promise<boolean>
  {
    if(!user)
    {
      return false;
    }
    let rewarded = false;
    if(!await this.purchaseService.isPro() && user.lifes < this.defaultVal && await this.checkTime())
    {
      rewarded = true;
      this.user = user;
      await this.giveReward();
    }
    return rewarded;
  }

  public async giveReward()
  {
    //Give user as many lifes as he needs to have default amount of lifes
    let lifes = 0;

    if(this.user)
    {
      lifes = this.user.lifes;
    }
    else {
      lifes = await this.storage.get("lifes");
    }
    
    if(lifes < this.defaultVal)
    {
      let diff = this.defaultVal - lifes;
      if(diff >= 1 && diff < (this.defaultVal + 1))
      {
        await this.addLifes(diff);
        await this.analyticsService.trackEvent("Free Daily Hearts Refill");
      }
    }
    this.setOriginTime();
  }

  public async getUserApi(): Promise<User>
  {
      return await this.httpClient.get<User>(`user`).toPromise();
  }

  public async addLifes(value: number)
  {
    this.user = await this.getUserApi();
    if(!this.user)
    {
      await this.storage.get("lifes").then((lifes) =>
      {
        lifes = Number.parseInt(lifes);
        let newvalue = lifes + value;
        this.storage.set("lifes", newvalue);
      });
    }
    else {
      await this.updateLifes(value.toString());
    }
  }

  public async updateLifes(value: String): Promise<String> {
    return await this.httpClient.put<String>(`user/lifes/${value}`, value).toPromise();
  }

  public async getUser(): Promise<User> {
    return await this.httpClient.get<User>(`user`).toPromise();
  }

  public async getLifes(): Promise<number>
  {
    this.user = await this.getUser();
    let value = 0;
    if(this.user)
    {
      value = this.user.lifes;
    }
    else {
      await this.storage.get("lifes").then((lifes: number) =>
      {
        value = lifes;
      });
    }
    return value;
  }

  public async setDefaultAnon() 
  {
    if(!await this.getAnonLifes())
    {
      this.storage.set("lifes", this.startVal);
    }
  }

  public async getAnonLifes()
  {
    let lifes: number | null = null;
    await this.storage.get("lifes").then((value: number) =>
    {
      lifes = value;
    });
    if(lifes != null)
    {
      return true;
    }
    else {
      return false;
    }
  }

  public getDefault(): number
  {
    return this.defaultVal;
  }

  public getStartVal(): number
  {
    return this.startVal;
  }
}
