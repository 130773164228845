import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { Platform } from "@ionic/angular";
import { AnalyticsService } from "./shared/analytics.service";
import { LangLevelPipe } from "./shared/pipes/lang-level.pipe";
import { PushService } from "./shared/push.service";
import { environment } from "../environments/environment";
import firebase from "firebase/compat/app"; 
//import { PushService } from "./shared/push.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  providers: [ LangLevelPipe ]
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public analytics: AnalyticsService,
    public readonly router: Router,
    public pushService: PushService
  ) {
    this.initializeFirebase();
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      if(this.platform.is("android"))
      {
        this.statusBar.overlaysWebView(true);
      }
      await this.analytics.init();
      await this.pushService.init();
      this.splashScreen.hide();
    });
  }

  public async initializeFirebase(): Promise<void> {
    if (this.platform.is('capacitor')) {
      return;
    }
    firebase.initializeApp(environment.firebaseConfig);
  }
}
