import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { mergeMapTo, take } from 'rxjs/operators';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  public hasPermission: boolean = false;
  public token: string = "";
  public message: any;

  constructor(
    private platform: Platform,
    public fireMessaging: AngularFireMessaging,
    //public fbx: FirebaseX,
    public router: Router,
    public browser: InAppBrowser
  ) {}

  public async init() {
      await this.setBadgeNumber(0);
      //this.webPermission();
      this.token = await this.fireMessaging.getToken.toPromise();
      localStorage.setItem("pushToken", this.token);
      this.onMessageReceived();
      console.log("PUSH TOKEN: " + this.token);
      LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
        console.log('PUSH NOTIFICATION DATA: ', notification);
        if(notification.notification.extra.action == "workout")
        {
          this.router.navigateByUrl("workout");
        }
      });
  }

  public webPermission() {
    this.fireMessaging.requestPermission
      .pipe(mergeMapTo(this.fireMessaging.tokenChanges))
      .subscribe(
        (token) => { this.token = token },
        (error) => { console.error(error); },
      );
      localStorage.setItem("pushToken", this.token);
  }

  public async requestPermission() {
      if (!this.platform.is('cordova')) {
        this.webPermission();
      }
      else {
        this.fireMessaging.requestToken.pipe(take(1)).subscribe(
          (token) => {
            this.token = token;
          },
          (error) => {
            console.error("Error getting token", error);
          }
        );
    }
  }

  private onMessageReceived() {
    this.fireMessaging.messages.pipe(take(1)).subscribe(
      (message) => this.handleAction(message),
      (error) => console.error("Error receiving message", error)
    );
  }

  public handleAction(data: any)
  {
    console.log("HANDLE RECEIVED NOTIFICATION: " + JSON.stringify(data) + " " + data.action);
    if(data.url && data.url.includes("grammario.de"))
    {
      this.browser.create(data.url, "_blank", "toolbarposition=top,location=no,fullscreen=no,toolbartranslucent=yes,closebuttoncolor=#000000,footer=no,closebuttoncaption=Fertig,lefttoright=yes,location=yes,presentationstyle=pagesheet,toolbarcolor=#FFFFFF,hidenavigationbuttons=yes,zoom=no");
    }
    if(data.action == "workout")
    {
      this.router.navigateByUrl("workout", {replaceUrl: true});
    }
  }

  public onTokenRefresh() {
    this.fireMessaging.tokenChanges.subscribe(
      (token) => {
        this.token = token;
        console.log(`Got a new token ${token}`);
      },
      (error) => console.error("Error refreshing token", error)
    );
  }

  private async setBadgeNumber(number: number) {
    if ('setAppBadge' in navigator) {
      try {
        await (navigator as any).setAppBadge(number);
      } catch (error) {
        console.error("Error setting badge number", error);
      }
    } else {
      console.warn("setAppBadge is not supported in this browser.");
    }
  }

  /*private async clearBadgeNumber() {
    if ('clearAppBadge' in navigator) {
      try {
        await (navigator as any).clearAppBadge();
      } catch (error) {
        console.error("Error clearing badge number", error);
      }
    } else {
      console.warn("clearAppBadge is not supported in this browser.");
    }
  }*/

  public async scheduleLocal(options: any)
  {
    await this.requestPermission();
    if(this.platform.is("capacitor"))
    {
      LocalNotifications.schedule(options);
    }
  }
}
