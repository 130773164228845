import { equals } from "./helpers";

/** All types that an exercise can have. */
export type ExerciseType = "Correction" | "FillBlanks" | "MultipleChoice" | "Order" | "Quiz";
export const ExerciseTypeValues = ["Correction", "FillBlanks", "MultipleChoice", "Order", "Quiz"];

export type ExerciseQuestionType =
    FillBlanksExerciseQuestion | MultipleChoiceExerciseQuestion | OrderExerciseQuestion | CorrectionExerciseQuestion | QuizExerciseQuestion;

export type IExerciseQuestionType =
    IFillBlanksExerciseQuestion | IMultipleChoiceExerciseQuestion | IOrderExerciseQuestion | ICorrectionExerciseQuestion | IQuizExerciseQuestion;

/** The numbers that a difficulty can have. */
export type DifficultyLevel = 1 | 2 | 3;

/**
 * Every exercise has to have these properties. They are normally populated through the args given within the exercise constructor.
 */
export interface IExercise {
    _id?: string;
    /** The title of a whole exercise. Like `Der, Die oder Das?`. */
    title: string;
    /** The description of this exercise. Like `Finde den korrekten Artikel für das angegebene Wort.` */
    description?: string;
    /** An array of questions. */
    questions: Array<IExerciseQuestionType>;
    /** An array of tags that exerciseQuestions have to have to be related to this exercise. */
    tags: Array<string>;
    /** The max number of questions within this exercise. */
    maxProgress: number;
    /** The difficulty of the exercise */
    difficulty: 1 | 2 | 3;
}

/**
 * Each exercise can have multiple questions to be answered. This interface is for those questions.
 * It has a `question` which is to be displayed, a `solution` which the app has to compare to the user input and a `hint`
 * to be shown if the user doesn't have a clue.
 */
export interface IExerciseQuestion<Q, S extends IEquals | string | number | Array<unknown>> {
    _id?: string;
    /** The question (normally a string) for this exercise part. */
    question: Q;
    /** The solution (normally a string) for this exercise part. */
    solution: S;
    /** A representation of the solution as a string. */
    solutionString: string;
    /** The type of this exercise. */
    type: ExerciseType;
    /** The difficulty level of an exercise question. */
    difficulty: DifficultyLevel;
    /** The tags of an exercise question used to identify its purpose. */
    tags: Array<string>;
    /** The hint that can be shown if the user doesn't know how to do anything. */
    hint?: string;
    /** Did the user answer the question correctly? */
    isCorrect?: boolean;
    /**  The solution that the user submitted */
    userAnswer?: S;
}

/**
 * Simple interface to have an equals-mechanism for non primitive types.
 */
export interface IEquals {
    /** Checks if the given value is equals the value executing this. */
    isEquals(value: IEquals): boolean;
}

/**
 * Class for the exercises. Just has some properties and helper functions.
 */
export class Exercise implements IExercise {
    public _id?: string;
    public title: string;
    public questions: Array<IExerciseQuestionType>;
    public tags: Array<string>;
    public maxProgress: number;
    public description?: string;
    public difficulty: 1 | 2 | 3;
    public done_workout?: boolean = false;

    public constructor(args: IExercise) {
        this._id = args._id;
        this.title = args.title;
        this.questions = args.questions.map(question => {
            switch (question.type) {
                case "Correction":
                    return CorrectionExerciseQuestion.fromObject(question as unknown as ICorrectionExerciseQuestion);
                case "FillBlanks":
                    return FillBlanksExerciseQuestion.fromObject(question as unknown as IFillBlanksExerciseQuestion);
                case "MultipleChoice":
                    return MultipleChoiceExerciseQuestion.fromObject(question as unknown as IMultipleChoiceExerciseQuestion);
                case "Quiz":
                    return QuizExerciseQuestion.fromObject(question as unknown as IQuizExerciseQuestion);
                default:
                    return OrderExerciseQuestion.fromObject(question as unknown as IOrderExerciseQuestion);
            }
        });
        this._id = (args as any)._id;
        this.tags = args.tags;
        this.maxProgress = args.maxProgress;
        this.description = args.description;
        this.difficulty = args.difficulty;
    }
}

/**
 * Abstract top class for every subclass of the exercises. Holds the most important properties and has some small helper functions.
 * Is a generic class, which means you can give it an type `Q` for the question and `S` that will be used as type for the solution.
 */
export abstract class ExerciseQuestion<Q, S extends IEquals | string | number | Array<unknown>> implements IExerciseQuestion<Q, S> {
    public _id?: string;
    public question: Q;
    public solution: S;
    public solutionString: string;
    public type: ExerciseType;
    public currentSolution?: S;
    public difficulty: DifficultyLevel;
    public tags: Array<string>;
    public hint?: string;
    public options?: Array<string>;
    public isCorrect?: boolean;
    public userAnswer?: S;

    public constructor(args: IExerciseQuestion<Q, S>) {
        this._id = args._id;
        this.question = args.question;
        this.solution = args.solution;
        this.solutionString = args.solutionString;
        this.type = args.type;
        this.difficulty = args.difficulty;
        this.tags = args.tags;
        this.hint = args.hint;
        this.isCorrect = args.isCorrect;
    }

    public convertCurrentSolutionToArrayWithOneString(currentSolution: any): Array<String>{
        let currentSolutionString: string = currentSolution.join(' ')
        let arrayWithOneString: Array<string> = [currentSolutionString]
        return arrayWithOneString;
    }


    /**
     * Checks if the given answer is equals the solution.
     */
    public isSolutionCorrect(): boolean {
        this.userAnswer = this.currentSolution;
        if (!this.currentSolution) {
            return false;
        }
        if ((this.currentSolution as any).isEquals) {
            return (this.currentSolution as IEquals).isEquals((this.solution as IEquals));
        } else if (Array.isArray(this.currentSolution)) {
            if (Array.isArray(this.solution)) {
                let currentSolutionConverted: any = this.convertCurrentSolutionToArrayWithOneString(this.currentSolution)
                for (let i = 0; i<this.solution.length; i++){
                    if (equals(currentSolutionConverted, this.solution![i])){
                        return true;
                    }
                }
            }
            return false;
        }
        return this.currentSolution === this.solution;
    }

}

export interface IOrderExerciseQuestion extends IExerciseQuestion<Array<string>, Array<string>> {}

/**
 * Class for the exercises questions where some given words have to be put in order.
 */
export class OrderExerciseQuestion extends ExerciseQuestion<Array<string>, Array<string>> {

    public constructor(_id: string | undefined, question: Array<string>, solution: Array<string>, solutionString: string,
                       difficulty: DifficultyLevel, tags: Array<string>, hint?: string) {
        super({ _id, question, solution, solutionString, type: "Order", difficulty, tags, hint });
        this.currentSolution = question;
    }

    /**
     * Creates a new instance of this class from a json object.
     *
     * @param currentQuestion - The object to create a class instance from.
     */
    public static fromObject(currentQuestion: IOrderExerciseQuestion): OrderExerciseQuestion {
        // Randomize order of elements.
        currentQuestion.question = currentQuestion.solution.slice().sort((_, __) => Math.random() - 0.5);
        //Prevent order from being the same as the solution
        if(currentQuestion.question.toString() == currentQuestion.solution.toString())
        {
            this.fromObject(currentQuestion);
        }
        return new OrderExerciseQuestion(currentQuestion._id, currentQuestion.question, currentQuestion.solution,
            currentQuestion.solutionString, currentQuestion.difficulty, currentQuestion.tags, currentQuestion.hint);
    }
}

export interface IMultipleChoiceExerciseQuestion extends IExerciseQuestion<Array<string | null>, string> {
    options: Array<string>;
}

/**
 * Class to be used for multiple choice exercises where one of the given options is the solution.
 */
export class MultipleChoiceExerciseQuestion extends ExerciseQuestion<Array<string | null>, string>
        implements IMultipleChoiceExerciseQuestion {

    public constructor(_id: string | undefined, public options: Array<string>, question: Array<string | null>, solution: string,
                       solutionString: string, difficulty: DifficultyLevel, tags: Array<string>, hint: string | undefined) {
        super({ _id, question, solution, solutionString, hint, type: "MultipleChoice", tags, difficulty });
    }

    /**
     * Creates a new instance of this class from a json object.
     *
     * @param currentQuestion - The object to create a class instance from.
     */
    public static fromObject(currentQuestion: IMultipleChoiceExerciseQuestion): MultipleChoiceExerciseQuestion {
        return new MultipleChoiceExerciseQuestion(currentQuestion._id, currentQuestion.options, currentQuestion.question,
            currentQuestion.solution, currentQuestion.solutionString, currentQuestion.difficulty, currentQuestion.tags,
            currentQuestion.hint);
    }
}

export interface IQuizExerciseQuestion extends IExerciseQuestion<Array<string | null>, string> {
    options: Array<string>;
}

/**
 * Class to be used for multiple choice exercises where one of the given options is the solution.
 */
export class QuizExerciseQuestion extends ExerciseQuestion<Array<string | null>, string>
        implements IQuizExerciseQuestion {

    public constructor(_id: string | undefined, public options: Array<string>, question: Array<string | null>, solution: string,
                       solutionString: string, difficulty: DifficultyLevel, tags: Array<string>, hint: string | undefined) {
        super({ _id, question, solution, solutionString, hint, type: "Quiz", tags, difficulty });
    }

    /**
     * Creates a new instance of this class from a json object.
     *
     * @param currentQuestion - The object to create a class instance from.
     */
    public static fromObject(currentQuestion: IQuizExerciseQuestion): QuizExerciseQuestion {
        return new QuizExerciseQuestion(currentQuestion._id, currentQuestion.options, currentQuestion.question,
            currentQuestion.solution, currentQuestion.solutionString, currentQuestion.difficulty, currentQuestion.tags,
            currentQuestion.hint);
    }
}

export interface IFillBlanksExerciseQuestion extends IExerciseQuestion<Array<string | null>, Array<string | null>> {}

export class FillBlanksExerciseQuestion extends ExerciseQuestion<Array<string | null>, Array<string | null>>
            implements IFillBlanksExerciseQuestion {

    public constructor(_id: string | undefined, question: Array<string | null>, solution: Array<string | null>, solutionString: string,
                       difficulty: DifficultyLevel, tags: Array<string>, hint: string | undefined) {
        super({ _id, question, solution, solutionString, type: "FillBlanks", difficulty, tags, hint });
        this.currentSolution = [...question] as string[];
    }

    /**
     * Creates a new instance of this class from a json object.
     *
     * @param currentQuestion - The object to create a class instance from.
     */
    public static fromObject(currentQuestion: IFillBlanksExerciseQuestion): FillBlanksExerciseQuestion {
        return new FillBlanksExerciseQuestion(currentQuestion._id, currentQuestion.question, currentQuestion.solution,
            currentQuestion.solutionString, currentQuestion.difficulty, currentQuestion.tags, currentQuestion.hint);
    }

    /**
     * Fills the blank or current word at the given index by replacing it with the new word (or null).
     *
     * @param index        - The index where the blank should be filled.
     * @param wordToFillIn - The word or not word to fill in.
     */
    public fillWordAtIndex(index: number, wordToFillIn: string | null): boolean {
        if (!this.currentSolution || index >= this.currentSolution.length) {
            return false;
        }
        if(wordToFillIn == "" || wordToFillIn == null)
        {
            wordToFillIn = "";
        }
        this.currentSolution[index] = wordToFillIn ?? "";
        //console.log("wordtofillin: " + wordToFillIn + " part: " + this.currentSolution[index]);
        return true;
    }

    /**
     * Returns if the word at the given index should be left blank.
     *
     * @param index - The index within the words array.
     */
    public isBlankAtIndex(index: number): boolean {
        return this.question[index] == null;
    }

    /**
     * Returns if the word at the given index is not blank.
     *
     * @param index - The index within the words array.
     */
    public isWordAtIndex(index: number): boolean {
        return typeof this.question[index] === "string";
    }

    /**
     * Checks if there are still blank parts left. (Nullish -> null | undefined | "" | -1 | etc)
     */
    public isCompletelyFilled(): boolean {
        return !!this.currentSolution?.every(val => !!val);
    }

    /**
     * Returns the indexes of the false parts.
     */
    public getFalseParts(): Array<number> {
        const falseParts: Array<number> = [];
        if (!this.currentSolution) {
            return [];
        }
        for (let i = 0; i < this.currentSolution.length; i++) {
            if (this.currentSolution[i] !== this.solution[i]) {
                falseParts.push(i);
            }
        }
        return falseParts;
    }
}

export interface ICorrectionExerciseQuestion extends IExerciseQuestion<Array<string>, string> {}

/**
 * Class to be used for exercises where an input has to be checked for correctness.
 */
export class CorrectionExerciseQuestion extends ExerciseQuestion<Array<string>, string>
            implements ICorrectionExerciseQuestion {
    public constructor(_id: string | undefined, question: Array<string>, solution: string, solutionString: string,
                       difficulty: DifficultyLevel, tags: Array<string>, hint: string | undefined) {
        super({ _id, question, solution, solutionString, type: "Correction", difficulty, tags, hint });
        this.currentSolution = "";
    }

    /**
     * Creates a new instance of this class from a json object.
     *
     * @param currentQuestion - The object to create a class instance from.
     */
    public static fromObject(currentQuestion: ICorrectionExerciseQuestion): CorrectionExerciseQuestion {
        return new CorrectionExerciseQuestion(currentQuestion._id, currentQuestion.question, currentQuestion.solution,
            currentQuestion.solutionString, currentQuestion.difficulty, currentQuestion.tags, currentQuestion.hint);
    }
}


export function exerciseQuestionFromJson(question: IExerciseQuestionType): ExerciseQuestionType {
    const isCorrection = (q: IExerciseQuestion<any, any>): q is ICorrectionExerciseQuestion => q.type === "Correction";
    const isOrder = (q: IExerciseQuestion<any, any>): q is IOrderExerciseQuestion => q.type === "Order";
    const isMultipleChoice = (q: IExerciseQuestion<any, any>): q is IMultipleChoiceExerciseQuestion => q.type === "MultipleChoice";
    const isQuiz = (q: IExerciseQuestion<any, any>): q is IQuizExerciseQuestion => q.type === "Quiz";
    // const isFillBlanks = (q: IExerciseQuestion<any, any>): q is IFillBlanksExerciseQuestion => q.type === "FillBlanks";

    if (isCorrection(question)) {
        return CorrectionExerciseQuestion.fromObject(question);
    }
    if (isOrder(question)) {
        return OrderExerciseQuestion.fromObject(question);
    }
    if (isMultipleChoice(question)) {
        return MultipleChoiceExerciseQuestion.fromObject(question);
    }
    if (isQuiz(question)) {
        return QuizExerciseQuestion.fromObject(question);
    }
    return FillBlanksExerciseQuestion.fromObject(question);
}
