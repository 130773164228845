// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//import { AdjustEnvironment, AdjustLogLevel } from "@awesome-cordova-plugins/adjust/ngx";

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDTclHcr25_gIvO3cpdeOeWPPxuEzJBO1s",
    authDomain: "grammario.firebaseapp.com",
    databaseURL: "https://grammario.firebaseio.com",
    projectId: "grammario",
    storageBucket: "grammario.appspot.com",
    messagingSenderId: "914433680811",
    appId: "1:914433680811:web:5283030348fb0ec1855eee",
    measurementId: "G-F43X91C422",
    vapidKey: "BLvNJG7YvUYG8a4CMk1ChMAM7Tt3iIH3zYqcr1-iGL-PZiRyH54X7AvKGdvn9-B752b5wyh_m1QjhI0yhj_blKs"
  },
  mixpanel: "5201eff23b244ccdfef3339444279823",
  appsflyer: {
    devKey: "auWSTk8SjET8e5Z8no9KdK",
    isDebug: true,
    appId: "id1554138665",
    onInstallConversionDataListener: true,
  },
  singular: {
    key: "grammario_bd07a506"
  },
  POSTHOG_KEY: "phc_lKG2QBTuhqAgF6jgUA7jeQ6WY58B3LCDuIgW9VJlDBM",
  POSTHOG_HOST: "https://eu.i.posthog.com",
  baseUrl: "https://api.grammario.de:3000"
  /*adjust: {
    iosToken: "vn7e8zxjrbwg",
    androidToken: "s2wfb7swdgcg",
    /*mode: AdjustEnvironment.Production,
    logmode: AdjustLogLevel.Suppress*/
  //},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
