import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Skill } from './skill';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public level: number = 0;
  public skills: Array<Skill> = [];

  constructor(public loadingController: LoadingController) { }
    public loader: any;
    public isLoading = false;

    async presentLoading() {
        if(!this.isLoading)
        {
            this.loader = await this.loadingController.create({
                message: '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>',
                duration: 2000,
                spinner: null,
                translucent: false,
                cssClass: 'spinner',
                backdropDismiss: false,
                showBackdrop: true
            });
            if(await this.loader.present())
            {
                this.isLoading = true;
            }
        }
        else {
            this.dismissLoading();
        }
    }

    async dismissLoading() {
        if(this.loader)
        {
            await this.loader.dismiss();
            this.isLoading = false;
        }
    }
}
