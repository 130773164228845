import { Injectable } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { AnalyticsService } from './analytics.service';
@Injectable({
  providedIn: 'root'
})
export class ApprateService {

  constructor(
    public browser: InAppBrowser,
    public alertCtrl: AlertController,
    public analyticsService: AnalyticsService,
    public platform: Platform
    ) {}

  public rateApp() 
  {
    if(this.platform.is("ios"))
    {
      //window.open("https://apps.apple.com/app/id1554138665?action=write-review", "_system");
      this.browser.create("https://apps.apple.com/app/id1554138665?action=write-review", "_system");
    }
    else if (this.platform.is("android")) {
      this.browser.create("https://play.google.com/store/apps/details?id=com.grammario", "_system");
    }
  }

  public async askOpinion() 
  {
    //localStorage.setItem("apprate", "3");
    if(localStorage.getItem("apprate") && localStorage.getItem("apprate") == "3")
    {
      const alert = await this.alertCtrl.create({
        cssClass: "hint",
        header: "Gefällt Dir Grammario?",
        message: "Das hilft uns, die App für Dich zu verbessern.",
        buttons: [
          {
            text: "Nein",
            cssClass: "secondary",
            handler: async () => {
              this.askFeedback();
              await this.analyticsService.trackEvent("AppRate Dislike");
              //window.open('https://grammario.de/feedback', '_blank');
            }
          }, {
            text: "Ja",
            handler: async () => {
              await this.showRatingPrompt();
              this.analyticsService.trackEvent("AppRate Like");
            }
          }
        ]
      });
      await alert.present();
    }
  }

  public async askFeedback() 
  {
      const alert = await this.alertCtrl.create({
        header: "Willst Du uns Feedback geben?",
        message: "Das hilft uns, die App für Dich zu verbessern.",
        buttons: [{
            text: "Nein",
            cssClass: "secondary",
            role: "cancel",
            handler: async () => {
              if(localStorage.getItem("apprate") == "-3")
              {
                localStorage.setItem("apprate", "never");
              }
              else {
                localStorage.setItem("apprate", "-1");
              }
              await this.analyticsService.trackEvent("AppRate Later Feedback");
            }
          },
          {
            text: "Ja",
            handler: async () => {
              if(this.platform.is("ios"))
              {
                this.browser.create("https://go.grammario.de/feedback_inapp", "_blank", "toolbarposition=top,fullscreen=no,toolbartranslucent=yes,closebuttoncolor=#000000,footer=no,closebuttoncaption=Fertig,lefttoright=yes,location=no,presentationstyle=pagesheet,toolbarcolor=#FFFFFF,hidenavigationbuttons=yes,zoom=no");
              }
              else if (this.platform.is("android"))
              {
                this.browser.create("https://go.grammario.de/feedback_inapp", "_blank", "fullscreen=no,toolbarposition=top,closebuttoncolor=#000000,footer=yes,footercolor=#ffffff,closebuttoncaption=Fertig,lefttoright=yes,location=no,presentationstyle=pagesheet,toolbarcolor=#FFFFFF,hidenavigationbuttons=yes,zoom=no");
              }
              localStorage.setItem("apprate", "never");
              await this.analyticsService.trackEvent("AppRate Feedback");
              //window.open('https://grammario.de/feedback', '_blank');
            }
          }
        ]
      });
      await alert.present();
  }

  public handleFlow()
  {
    if(localStorage.getItem("apprate"))
    {
      switch(localStorage.getItem("apprate"))
      {
        case "1":
          localStorage.setItem("apprate", "2");
          break;
        case "2":
          localStorage.setItem("apprate", "3");
          break;
        case "never" || "rated":
          break;
        case "-1":
          localStorage.setItem("apprate", "-2");
          break;
        case "-2":
          localStorage.setItem("apprate", "-3");
          break;
        case "-3":
          this.askFeedback();
          break;
        default:
          localStorage.setItem("apprate", "1");
          break;
      }
    }
    else {
      localStorage.setItem("apprate", "1");
    }
  }

  public async showRatingPrompt() 
  {
    const alert = await this.alertCtrl.create({
      cssClass: "hint",
      header: "Möchtest Du Grammario bewerten?",
      message: "Es geht ganz schnell und würde uns sehr weiterhelfen!",
      buttons: [
        {
          text: "Nicht mehr fragen",
          cssClass: "secondary",
          handler: () => {
            localStorage.setItem("apprate", "never");
            this.analyticsService.trackEvent("AppRate Never Ask Again");
          }
        },
        {
          text: "Später",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            localStorage.setItem("apprate", "1");
            this.analyticsService.trackEvent("AppRate Later Rating");
          }
        }, 
          {
            text: "Bewerten",
            handler: () => {
              this.rateApp();
              localStorage.setItem("apprate", "rated");
              this.analyticsService.trackEvent("AppRate Rated");
            }
          }
      ]
    });
    await alert.present();
  }
}
