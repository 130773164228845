import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat/"
import { AngularFireMessagingModule, SERVICE_WORKER } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { IonicModule, IonicRouteStrategy, isPlatform } from "@ionic/angular";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AngularFireAuthGuard } from "@angular/fire/compat/auth-guard";
import { GooglePlus } from "@awesome-cordova-plugins/google-plus/ngx";
import { ScreenTrackingService } from '@angular/fire/analytics';
import { NgChartsModule } from "ng2-charts";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTPInterceptor } from "./interceptors/requestInterceptor";
import { AnalyticsService } from "./shared/analytics.service";
import { ExerciseService } from "./shared/exercise.service";
import { SpinnerService } from "./shared/spinner.service";
import { WorkoutService } from "./shared/workout.service";
import { AuthService } from "./shared/auth.service";
import { UserService } from "./shared/user.service";
import { IonicStorageModule } from '@ionic/storage-angular';
import { DailyStreakService } from "./shared/daily-streak.service";
import { OnboardingService } from "./shared/onboarding.service";
import { ProgressService } from "./shared/progress.service";
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { Validation } from "./shared/helpers";
import { PushService } from "./shared/push.service";
//import { Appsflyer } from '@awesome-cordova-plugins/appsflyer/ngx';
import { ApprateService } from "./shared/apprate.service";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { AngularFireAnalyticsModule, CONFIG } from "@angular/fire/compat/analytics";
//import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
//import { AdMob } from '@admob-plus/ionic/ngx';

@NgModule({
    declarations: [AppComponent],
    imports: [
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireAnalyticsModule,
        AngularFireMessagingModule,
        BrowserModule,
        NgChartsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IonicModule.forRoot({
            _forceStatusbarPadding: isPlatform("android")
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AngularFireAuthGuard,
        GooglePlus,
        ScreenTrackingService,
        ExerciseService,
        //Adjust,
        //Appsflyer,
        InAppBrowser,
        ApprateService,
        AnalyticsService,
        PushService,
        SpinnerService,
        ProgressService,
        UserService,
        AuthService,
        WorkoutService,
        OnboardingService,
        Validation,
        IonicStorageModule,
        DailyStreakService,
        SignInWithApple,
        //AdMob,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPInterceptor,
            multi: true
        },
        { provide: CONFIG, useValue: {
            send_page_view: true,
            allow_ad_personalization_signals: true,
            anonymize_ip: true
        }},
        //{ provide: LOCALE_ID, useValue: "de" },
        { provide: SERVICE_WORKER, useFactory: () => typeof navigator !== 'undefined' && navigator.serviceWorker?.register('firebase-messaging-sw.js', { scope: '__' }) || undefined },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
