import { Pipe, PipeTransform } from '@angular/core';
import { Exercise } from '../exercises.interface';

@Pipe({
  name: 'langLevel'
})
export class LangLevelPipe implements PipeTransform {

  public transform(value: Exercise[], level: string): Exercise[] {
    return value.filter(e => this.checkTags(e.tags, level));
  }

  public checkTags(tags: string[], level: string): boolean
  {
    let found = false;
    for(let i = 0; i < tags.length; i++)
    {
      if(tags[i].includes(level))
      {
        found = true;
      }
    }
    return found;
  }
}
