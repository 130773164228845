import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ToastController } from "@ionic/angular";
import { from, Observable } from "rxjs";

import { environment } from "../../environments/environment";
import { AuthService } from "../shared/auth.service";
import firebase from "firebase/compat/app"; 
import "firebase/compat/auth" 
@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
    private authToken: any;
    public fireUser?: firebase.User;

    constructor(public toastController: ToastController, 
        public authService: AuthService,
        public readonly auth: AngularFireAuth) {
            this.auth.user.subscribe(async user => {
                this.fireUser = user;
            });
        }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleAccess(req, next));
    }

    private async handleAccess(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        let apiReq = req.clone({ url: `${environment.baseUrl}/${req.url}` });

        this.authToken = await this.fireUser!.getIdToken();
        if(this.authToken)
        {
            apiReq = apiReq.clone({ headers: apiReq.headers.set("Authorization", `Bearer ${this.authToken}`) });
        }
        return next.handle(apiReq).toPromise();
    }
}
