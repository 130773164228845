import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import {
  Exercise,
  exerciseQuestionFromJson,
  ExerciseQuestionType,
  IExercise,
  IExerciseQuestionType,
} from "./exercises.interface";
import { Task } from "./task";

/**
 * Service that holds the exercises.
 */
@Injectable({
  providedIn: "root",
})
export class ExerciseService {
  public static storedExercise?: Exercise;

  public exercises: Array<Exercise> = [];

  public exerciseQuestions: Array<ExerciseQuestionType> = [];

  public tags: Array<string> = [];
   
  public activeExercise?: Exercise;

  public isInitialized = new BehaviorSubject(false);

  private correctAnswers = 0;

  public tasks: Array<Task> = [];

  public constructor(private httpClient: HttpClient) {
    Promise.all([this.getAllExercises(), this.getAllTags(), this.getAllQuestions(), this.getAllTasks()])
      .then(() => {
        this.isInitialized.next(true);
      });
  }

  public async getAllExercises(): Promise<void> {
    this.exercises = (await this.httpClient.get<Array<IExercise>>("exercise").toPromise()).map((e) => new Exercise(e));
  }

  public async returnAllExercises(): Promise<Exercise[]> {
    return (await this.httpClient.get<Array<IExercise>>("exercise").toPromise()).map((e) => new Exercise(e));
  }

  public getExerciseArray() {
    return this.exercises;
  }

  public async getAllQuestions(): Promise<void> {
    this.exerciseQuestions = (await this.httpClient.get<Array<IExerciseQuestionType>>("exercise/question").toPromise())
      .map((e) => exerciseQuestionFromJson(e));
  }

  public async createExercise(exercise: IExercise): Promise<void> {
    this.exercises.push(new Exercise(await this.httpClient.post<IExercise>("exercise", exercise).toPromise()));
  }

  public async updateExercise(exercise: IExercise): Promise<void> {
    this.exercises.splice(this.exercises.findIndex((e) => e._id === exercise._id), 1);
    this.exercises.push(new Exercise(await this.httpClient.put<IExercise>(`exercise/${exercise._id}`, exercise).toPromise()));
  }

  public async createQuestions(questions: IExerciseQuestionType[]): Promise<void> {
    const results = [];
    for (const question of questions) {
      results.push(this.httpClient.post<IExerciseQuestionType>("exercise/question", question).toPromise());
    }
    await Promise.all(results);
    await this.getAllExercises();
  }

  public async updateQuestions(question: IExerciseQuestionType): Promise<void> {
    await this.httpClient.put<IExerciseQuestionType>(`exercise/question/${question._id}`, question).toPromise();
    await this.getAllExercises();
  }

  public getExerciseWithId(id: string): Exercise | undefined {
    return this.exercises.find((e) => e._id === id);
  }

  public async getExerciseByID(id: string): Promise<Exercise> {
    if(this.exercises.length == 0)
    {
      await this.getAllExercises();
    }
    return this.exercises.find((e) => e._id === id);
  }

  public async getQuestionWithId(id: string): Promise<ExerciseQuestionType | undefined > {
    return this.exerciseQuestions.find((q) => q._id === id);
  }

  private async getAllTags(): Promise<void> {
    this.tags = (await this.httpClient.get<Array<string>>("exercise/tags").toPromise());
    console.log(this.tags);
  }

  public clearCurrExercise(): void {
    if (typeof this.activeExercise !== "undefined") {
      this.activeExercise = undefined;
    }
  }

  /*public getProgress(skills: Array<Skill>, exerciseid: string): number
  {
      let skill = skills.find(skill => skill.exerciseid === exerciseid);
      if(skill)
      {
          let neededXP = 0;
          let currentLevel = skill.level!;
          let toadd = 15 + (currentLevel * 5);
          if(toadd <= 100)
          {
              neededXP = toadd;
          }
          else {
              neededXP = 100;
          }
          return (skill.xp! / neededXP);
      }
      else {
          return 0;
      }
  }*/

  public getCorrectAnswers(results: Array<IExerciseQuestionType>): number {
    this.correctAnswers = 0;
    results.forEach(res => {
      if (res.isCorrect) {
        this.correctAnswers++;
      }
    });
    return this.correctAnswers;
  }

  public async getTaskById(id: string): Promise<Task | undefined > {
    return this.tasks.find((t) => t._id === id);
  }

  public async getTaskByTag(tag: string): Promise<Task | undefined> {
    await this.getAllTasks();
    let foundtask = undefined;

    for(let task of this.tasks)
    {
      for(let i = 0; i < task.tags.length; i++)
      {
        if(task.tags[i] == tag)
        {
          foundtask = task;
          break;
        }
      }
    }
    return foundtask;
  }

  public async createTask(task: Task): Promise<void> {
    this.tasks.push(await this.httpClient.post<Task>("exercise/task", task).toPromise());
  }

  public async updateTask(task: Task): Promise<void> {
    this.tasks.splice(this.tasks.findIndex((t) => t._id === task._id), 1);
    this.tasks.push((await this.httpClient.put<Task>(`exercise/task/${task._id}`, task).toPromise()));
  }

  public async getAllTasks(): Promise<void> {
    this.tasks = (await this.httpClient.get<Array<Task>>("exercise/tasks").toPromise());
  }
}
