import { enableProdMode, /*isDevMode*/ } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { defineCustomElements } from "@teamhive/lottie-player/loader";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import posthog from "posthog-js";
import LogRocket from 'logrocket';
declare var cordova;

if (environment.production) {
  enableProdMode();
}
defineCustomElements(window);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

posthog.init(
  environment.POSTHOG_KEY!,
  {
    api_host: environment.POSTHOG_HOST,
    //screenshotMode: true,
    person_profiles: 'always',
    session_recording: {
      recordBody: true,
      recordCrossOriginIframes: true,
      recordHeaders: true,
      inlineStylesheet: true,
    },
    capture_pageview: true,
    debug: true,
    mask_all_text: false,
    mask_all_element_attributes: false,
  }
)

//posthog.startSessionRecording();


/*if(!isDevMode())
  {*/
    LogRocket.init('l1gkha/grammario-prod',
      {
        dom: {
          baseHref: "https://grammario.vercel.app/"
        },
      });
  //}*/
