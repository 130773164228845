import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DailyStreakService } from './daily-streak.service';
import { ExerciseService } from './exercise.service';
import { Exercise } from './exercises.interface';
import { Skill } from './skill';
import { UserService } from './user.service';
//import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  public level: number = 0;
  public skills: Array<Skill> = [];

  constructor(private httpClient: HttpClient, 
    public exerciseState: ExerciseService,
    public userService: UserService,
    public dailyStreak: DailyStreakService
    /*private spinnerService: SpinnerService*/) { 
      //Promise.all([this.getSkillsByUid]);
  }

  public async getSkillsByUid(): Promise<Array<Skill>> {
    //await this.spinnerService.presentLoading();
    return (await this.httpClient.get<Array<Skill>>(`skill`).toPromise());
  }

  public async getSkillWithId(id: string): Promise<Skill | undefined> {
    return this.skills.find((s) => s._id === id);
  }

  public async getSkillByExercise(exerciseid: string): Promise<Skill | Partial<Skill>> {
    console.log("SKILLS " + this.skills)
    this.skills = await this.getSkillsByUid();
    let skill = this.skills.find((s) => s.exerciseid === exerciseid);
    if(!skill)
    {
      return {level: 1, xp: 0}
    }
    return skill;
  }

  public async getExerciseBySkill(id: string): Promise<Exercise | undefined> {
    return this.exerciseState.getExerciseWithId(this.skills.find((s) => s._id === id)!.exerciseid);
  }

  public async getLevel(skillid: string): Promise<Number> {
    return (await this.httpClient.get<Number>(`skill/id/${skillid}`).toPromise());
  }

  public async addProgress(skillid: string, correct: boolean): Promise<number> {
    return await this.httpClient.put<number>(`skill/${skillid}`, {"correct": correct}).toPromise();
  }

  public async updateSkill(skillid: string, skill: Partial<Skill>): Promise<Skill | void> {
    return await this.httpClient.put<Skill>(`skill/${skillid}`, skill).toPromise();
  }

  public async createSkill(skill: Partial<Skill>): Promise<Skill | void> {
    return await this.httpClient.post<Skill>("skill", skill).toPromise();
  }

  public async removeSkill(skillid: string): Promise<boolean | void> {
    return await this.httpClient.delete<boolean>(`skill/remove/${skillid}`).toPromise();
  }

  /* POINTS */
  /*public async getPoints(): Promise<number> {
    return await this.httpClient.get<number>(`user/points/`).toPromise();
  }*/

  public async getLifes(uid: string): Promise<Number> {
    return (await this.httpClient.get<Number>(`lifes/${uid}`).toPromise());
  }

  public async updatePoints(value: String): Promise<String> {
    return await this.httpClient.put<String>(`user/points/${value}`, value).toPromise();
  }

  public async updateLifes(value: String): Promise<String> {
    return await this.httpClient.put<String>(`user/lifes/${value}`, value).toPromise();
  }
  
  public async getProgress(exerciseid: string): Promise<number>
  {
      this.skills = await this.getSkillsByUid();
      let skill = this.skills.find(skill => skill.exerciseid === exerciseid);
      if(skill)
      {
          let neededXP = 0;
          let currentLevel = skill.level!;
          let toadd = 15 + (currentLevel * 5);
          if(toadd <= 100)
          {
              neededXP = toadd;
          }
          else {
              neededXP = 100;
          }
          return (skill.xp! / neededXP);
      }
      else {
          return 0;
      }
  }
}
